<template>
  <div class="w-100">
    <div class="table-responsive mt-3">
      <table  class="table  table-bordered table-hover table-lg mt-lg mb-0">
        <thead>
          <tr>
            <th width="3%" class="text-center">#</th>
            <th width="7%" class="text-center">Codigo</th>
            <th width="40%" class="text-center">Nombre</th>
            <th width="10%" class="text-center">U.M.</th>
            <th width="9%" class="text-center">Cantidad</th>
            <!-- <th width="9%" class="text-center">Cap.</th>
            <th width="7%" class="text-center">Cap. Total</th> -->
            <!-- <th width="9%" class="text-center">Merma</th> -->
            <!-- <th width="7%" class="text-center">Cap. Final</th> -->
            <th width="7%" class="text-center">Acciones</th>
          </tr>
        </thead>
        <tbody v-for="(item, it) in transformation_detail" :key="it">
          <tr>
              <td class="align-middle text-center">{{ it + 1 }}</td>
              <td class="align-middle text-center">{{ item.code }}</td>
              <td class="align-middle text-left">{{ item.name  }}</td>
              <td class="align-middle text-center">{{ item.unit_measure }}</td>
              <td class="align-middle text-center">
                <input type="number" step="any" @change="EditDetail(it)" class="form-control text-right" v-model="item.quantity">
              </td>
              <!-- <td class="align-middle text-center">
                <input type="number" step="any" @change="EditDetail(it)" class="form-control text-right" v-model="item.capacity">
              </td> 
              <td class="align-middle text-right">{{ item.total_capacity }}</td>
              <td class="align-middle text-center">
                <input type="number" step="any" @change="EditDetail(it)" class="form-control text-right" v-model="item.decrease">
              </td>
              <td class="align-middle text-right">{{ item.total_final_capacity }}</td> -->
              <td class="align-middle text-center">
                <button type="button" @click="DeleteDetail(it)" class="btn btn-danger"><i class="fas fa-trash-alt"></i></button>  
              </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<style scoped>
.table-responsive{
  min-height: 15px !important;
}
</style>
<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState,mapActions } from "vuex";
import EventBus from '@/assets/js/EventBus';

export default {
  name: "InputDetail",
  data() {
    return {
    
    };
  },
  mounted() {

  
  },
  methods: {
    EditDetail,
    DeleteDetail,
 
    ...mapActions('Transformation',['mLoadEditTransformationDetail']),
    ...mapActions('Transformation',['mLoadDeleteTransformationDetail']),
    
  },
  computed: {
    ...mapState('Transformation',['transformation_detail']),
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};


function EditDetail(index) {
  let name = this.transformation_detail[index].name;
  this.$notify({ group: 'alert', title: 'Sistema', text:'Se ha modificado el producto ' + name, type: 'warn'})
  this.mLoadEditTransformationDetail(index)
}

function DeleteDetail(index) {
  let name = this.transformation_detail[index].name;
  this.$notify({ group: 'alert', title: 'Sistema', text:'Se ha eliminado el producto ' + name, type: 'warn'})
  this.mLoadDeleteTransformationDetail(index)
}
</script>
